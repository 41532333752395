import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';
import {HttpHeaders} from '@angular/common/http';

@Injectable()
export class DataFetchService {
    constructor(private apollo: Apollo) {
    }

    fetchGraphQlResponse(query: any, endpointNumber=null, isSearch = false,): any {
        const queryStatement = gql`
            ${query}
        `;
        const endpoint = endpointNumber? endpointNumber: 'endpoint1';
        if (isSearch) {
            return this.apollo.use('endpoint2').watchQuery<any>({
                query: queryStatement,
            }).valueChanges;
        } else {
            return this.apollo.use(endpoint).watchQuery<any>({
                query: queryStatement,
                errorPolicy: 'all',
                context: {
                    headers: new HttpHeaders().set(
                        'Authorization',
                        'JWT ' + localStorage.getItem('accessToken')
                    ).set('Authsession',localStorage.getItem('cookie')),
                },
            }).valueChanges;
        }
    }

    fetchMutationGraphQLResponse(query: any, endpointNumber=null, variables: any): any{
        const gqlQuery = gql `
            ${query}
        `;
        const endpoint = endpointNumber? endpointNumber: 'endpoint1';
        return this.apollo.use(endpoint).mutate<any>({
            mutation: gqlQuery,
            context: {
                headers: new HttpHeaders()
                  .set('Authorization', 'JWT ' + localStorage.getItem('accessToken'))
                  .set('Authsession', localStorage.getItem('cookie')),
                useMultipart: true,
            },
            variables: variables,
            fetchPolicy: 'no-cache',
        });

    }        

}
