// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    productCode:'zs',
    vendorText:'Source',
    production: true,
    graphQLUri: 'https://datalibrary.zssandbox.com/api/graphql',
    API_END_POINT: 'https://localhost:4200/',
    graphQLSearchUri: 'https://datalibrary.zssandbox.com/api/graphql',
    graphQLUriBmc: 'http://k8s-devnsdat-ingressd-0f243a69db-215578525.us-east-1.elb.amazonaws.com/graphql',
    chat_END_POINT:'https://datalibrary.zssandbox.com/chat'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
